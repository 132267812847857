<template>
    <img
        class="w-full h-full object-cover"
        v-if="!src.endsWith('.mp4')"
        :src="src"
        :alt="getFileNameFormPath(src)"
    />
    <video class="w-full h-full object-cover" v-else autoplay loop>
        <source :src="src" type="video/mp4" />
    </video>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { getFileNameFormPath } from "@/ui-elements/file-browser/useFileBrowser"

export default defineComponent({
    name: "ImageOrVideo",
    props: {
        src: {
            type: String,
            default: "",
        },
    },
    setup() {
        return {
            getFileNameFormPath,
        }
    },
})
</script>
