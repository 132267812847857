import { Ref, ref } from "vue"
import { createToast, withProps } from "mosha-vue-toastify"
import ToastNotification from "@/ui-elements/toast-notification/ToastNotification.vue"
import i18n from "@/i18n"
import { useSettingsStore } from "@/store/Settings"
import { usePrintersStore } from "@/store/Printers"
import { useDelivererStore } from "@/store/Deliverer"
import { useUserStore } from "@/store/User"
import { usePosMenusStore } from "@/store/PosMenus"
import { useUtilsStore } from "@/store/Utils"
import { useTablesStore } from "@/store/Tables"
import { useOrdersStore } from "@/store/Orders"
import { useZipcodesStore } from "@/store/Zipcodes"
import { useCartStore } from "@/store/cart/Cart"
import { websocket } from "@/services/WebsocketService"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { useCartDiscountsStore } from "@/store/CartDiscount"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { useOpeningHoursStore } from "@/store/OpeningHours"
import { useClosingHoursStore } from "@/store/ClosingHours"
import { useOBMessagesStore } from "@/store/OBMessage"
import axios, { AxiosResponse } from "axios"
import { useAPIStore } from "@/store/API"
import { syncOrders } from "@/services/SyncOrdersService"
import { sendKiosOrdersToExternalAPI } from "@/utils/external-APIs/sot/sotUtils"
import { useCallerId } from "@/utils/useCallerId"
import { offlineModeStore } from "@/store/offlineMode"
import { multiLocationStore } from "@/store/MultiLocation"

export type Hydratable = () => Promise<boolean>

/**
 * The DataHydrationService solves the following problems:
 *
 * - It ensures that all data is loaded (eventually)
 * - It ensures that data isn’t loaded when not necessary (websockets play a big role)
 * - Simultaneously it ensures that data will be available offline for RiceCooker to use
 *   (axios interceptors ensure that all retrieved data is sent to RC)
 *
 * The data hydration strategy is as follows:
 * - Initially when we load the application, we load ALL the data at once, this way we have
 *   everything relevant in memory, making the entire application very snappy. This has the
 *   drawback of having a relatively long time to initialize, but that's okay
 * - Whenever a websocket connection is established, we can safely assume that we'll always
 *   receive notifications when data changes, only then we'll fetch the relevant data from the API.
 *   Examples:
 *      - WS notification 'product.updated' came in, this means that we'll rehydrate our posMenu module
 *      - WS notification 'order.created' came in, rehydrate 'orders' module
 */
class DataHydrationService {
    private _interval: NodeJS.Timer | undefined = undefined
    private _hydratables: { [key: string]: Hydratable } = {}
    private _count: number = 0
    private _timestamps: { [key: string]: number } = {}
    private _counts: { [key: string]: number } = {}
    public rcTablesCreated: boolean = false
    public lastActivity: number = 0
    public status: Ref<string> = ref("")
    public isHydratingAllModules: Ref<Boolean> = ref(false)

    constructor(hydratables: { [key: string]: Hydratable }) {
        this._hydratables = hydratables
        this._count = Object.keys(hydratables).length
    }

    get counts(): { [key: string]: number } {
        return this._counts
    }
    get timestamps(): { [key: string]: number } {
        return this._timestamps
    }
    get hydratables(): string[] {
        return Object.keys(this._hydratables)
    }

    async start(multiLocationSwapped: boolean = false): Promise<void> {
        if (this.isActive()) {
            return
        }

        DataHydrationService._log(
            "Start, loaded modules: " + Object.keys(this._hydratables).join(",")
        )

        this.rcTablesCreated = false
        if (RiceCooker.isPresent()) {
            await this._createRcTables()
        }

        this._interval = setInterval(async (): Promise<void> => {
            await this.hydrateAll()
        }, 60000) // every minute

        await this.hydrateAll(true, false, multiLocationSwapped)
    }

    _createRcTables() {
        return new Promise((resolve): void => {
            const attempt = async (): Promise<boolean> => {
                try {
                    DataHydrationService._log("RC: Creating tables...")
                    await window.riceCooker.createTables()
                    this.rcTablesCreated = true
                    DataHydrationService._log("RC: Finished creating tables...")
                    resolve(true)
                    return true
                } catch (e) {
                    setTimeout(attempt, 2000)
                    DataHydrationService._log(
                        "RC: Unable to create tables, retrying..."
                    )
                    console.error(e)
                    return false
                }
            }
            attempt()
        })
    }

    stop(): void {
        if (this._interval) {
            DataHydrationService._log("Stop")
            //@ts-ignore
            clearInterval(this._interval)
            this._interval = undefined
            this._timestamps = {}
            this._counts = {}
            this.lastActivity = 0
        }
    }

    async hydrateAll(
        isInit: boolean = false,
        force: boolean = false,
        multiLocationSwapped: boolean = false
    ): Promise<void> {
        const { t: translate } = i18n.global

        this.isHydratingAllModules.value = true
        this.status.value = translate("num_modules", { num: this._count })

        let toast
        if (window.ob.debug.length > 0) {
            if (isInit || force) {
                toast = this.showToast(this.status, force)
            }
        }

        let i: number = 0
        this.lastActivity = Math.floor(new Date().getTime() / 1000)
        DataHydrationService._log("Hydrating all modules...")
        const start: number = new Date().getTime()
        for (const module in this._hydratables) {
            i++
            try {
                this.status.value = translate("modules_progress", {
                    progress: i + " / " + this._count,
                })

                // For a smooth experience, we don't rehydrate the pos menu and multi location stores,
                // when we are in a multi location account and swap the location
                if (
                    multiLocationSwapped &&
                    ["posMenu", "multiLocation"].includes(module)
                ) {
                    continue
                }

                await this.hydrateModule(module, isInit || force)
            } catch {
                // ignore
            }
        }
        DataHydrationService._log(
            `OK, took ` + (new Date().getTime() - start) + "ms"
        )

        if (toast) {
            toast.close()
        }

        this.status.value = `${translate("num_modules", {
            num: this._count,
        })} ${translate("ready")}`
        this.isHydratingAllModules.value = false
    }

    async hydrateModule(
        module: string,
        force: boolean = false,
        interval: number = 3600000 // only hydrate if the module was not hydrated for more than 1hr
    ): Promise<void> {
        // don't hydrate if not signed in
        if (!useAPIStore().hasBearerToken()) {
            return
        }

        // check if recently already hydrated (1hr)
        const lastHydrated: number = this._timestamps[module] || 0
        if (force || new Date().getTime() - lastHydrated >= interval) {
            DataHydrationService._log(
                `Hydrating "${module}", force: ${force}...`
            )
            const start: number = new Date().getTime()
            const result: boolean = await this._hydratables[module]()
            if (result) {
                this._timestamps[module] = new Date().getTime()
                this._counts[module] = this._counts[module] + 1 || 1
                DataHydrationService._log(
                    `OK "${module}", took ` +
                        (new Date().getTime() - start) +
                        "ms"
                )
            } else {
                DataHydrationService._log(
                    `FAILED "${module}", took ` +
                        (new Date().getTime() - start) +
                        "ms"
                )
            }
        }
    }

    /**
     * We can forcefully load a module whenever a websocket connection isn't established
     */
    async hydrateModuleIfNoWebsocket(
        module: string,
        force: boolean = true
    ): Promise<void> {
        if (!websocket.isConnected()) {
            await this.hydrateModule(module, force)
        }
    }

    showToast(text: Ref<string>, wasForced: boolean = false) {
        const { t: translate } = i18n.global

        return createToast(
            withProps(ToastNotification, {
                toastTitle: wasForced
                    ? translate("orderbuddy_is_refreshing")
                    : translate("orderbuddy_is_starting"),
                toastContent: text,
                toastType: "loading",
            }),
            {
                position: "bottom-left",
                type: "default",
                timeout: -1,
                showCloseButton: false,
                swipeClose: false,
                hideProgressBar: true,
            }
        )
    }

    isActive(): boolean {
        return this._interval !== undefined
    }

    waitForHydration(modules: string[]): Promise<void> {
        return new Promise((resolve): void => {
            const check = (): void => {
                let loaded: boolean = true
                for (let i: number = 0; i < modules.length; i++) {
                    if ((this.counts[modules[i]] || 0) === 0) {
                        loaded = false
                    }
                }

                if (loaded) {
                    clearInterval(interval)
                    resolve()
                }
            }
            const interval = setInterval(check, 2000)
            check()
        })
    }

    private static _log(message: string): void {
        if (
            window.ob.hasDebugModule("DataHydration") ||
            RiceCooker.isPresent()
        ) {
            console.log(`[DataHydration] ${message}`)
        }
    }
}

export const dataHydration: DataHydrationService = new DataHydrationService({
    health: async (): Promise<boolean> => {
        return await offlineModeStore()
            .apiHealthCheck()
            .then(() => {
                return true
            })
    },
    user: async (): Promise<boolean> => {
        return await useUserStore()
            .fetchUser()
            .then((result: boolean) => {
                return result
            })
    },
    multiLocation: async (): Promise<boolean> => {
        return await multiLocationStore().fetchLocations(true)
    },
    voip: async (): Promise<boolean> => {
        const { subscribeToCallerIdEvents } = useCallerId()
        return await subscribeToCallerIdEvents()
    },
    settings: async (): Promise<boolean> => {
        const settingsStore = useSettingsStore()

        try {
            return await settingsStore.fetchSettings()
        } finally {
            await useCartStore().updateCart()

            usePosMenusStore().selectedMenuId = Number(
                settingsStore.settings.counter_menu
            )

            if (useMasterSlave().isActive.value) {
                // When no master_ob_id set, then mark this one as master
                if (!settingsStore.settings.master_ob_id) {
                    await useMasterSlave().setMaster()
                }
            }

            // When language does not match routing config language, then update
            const locale: string =
                localStorage.getItem("localLocale") === "default"
                    ? settingsStore.settings.ob_language
                    : localStorage.getItem("localLocale") ?? "nl"

            if (locale !== settingsStore.routesConfigLanguage) {
                if (!offlineModeStore().isOffline) {
                    await settingsStore.fetchRoutesConfig()
                }
            }
        }
    },
    orders: async (): Promise<boolean> => {
        const fetchOrders: boolean = await useOrdersStore().fetchOrders()
        await useOrdersStore().fetchKitchenOrders()
        await sendKiosOrdersToExternalAPI()
        return fetchOrders
    },
    utilCountries: (): Promise<boolean> => {
        const utils = useUtilsStore()
        if (Object.keys(utils.countries).length) {
            return Promise.resolve(true)
        }
        return utils.fetchCountries()
    },
    utilPaymentMethods: () => useUtilsStore().fetchPaymentMethods(),
    utilLocales: (): Promise<boolean> => {
        const utils = useUtilsStore()
        if (Object.keys(utils.locales).length) {
            return Promise.resolve(true)
        }
        return utils.fetchLocales()
    },
    printers: () => usePrintersStore().fetchPrinters(),
    deliverers: () => useDelivererStore().fetchDeliverers(),
    customers: () => axios.get("/client/customers"),
    posMenu: () =>
        usePosMenusStore()
            .fetchMenus()
            .finally(() => {
                useCartStore().updateCart()
            }),
    tables: () => useTablesStore().fetchTables(),
    zipcodes: () =>
        useZipcodesStore()
            .fetchZipcodes()
            .finally((): void => {
                useCartStore().updateCart()
            }),
    discounts: () => useCartDiscountsStore().fetchCartDiscounts(),
    openingHours: () => useOpeningHoursStore().fetchOpeningHours(),
    closingHours: () => useClosingHoursStore().fetchClosingHours(),
    obMessages: () => useOBMessagesStore().fetchObMessages(),
    localIps: async (): Promise<boolean> => {
        if (!RiceCooker.isPresent()) {
            return true
        }

        syncOrders.start()

        if (offlineModeStore().isOffline) {
            return true
        }

        try {
            const response: AxiosResponse<any> = await axios.get(
                "/client/local_ips"
            )
            const data: { [key: string]: string } = response.data.data
            const masterSlave = useMasterSlave()
            await window.riceCooker.replicationIps(
                // @ts-ignore TODO: we will fix this asap
                Object.keys(data).map((obId: string) => ({
                    ip: data[obId],
                    state: "available",
                    is_self: obId === masterSlave.obId,
                }))
            )
            return true
        } catch (e) {
            return false
        }
    },
})
