import {
    ORDER_DELIVERIES,
    ORDER_STATUSES_FOR_LABELS,
    Order,
} from "@/interface/orders/Order"
import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"
import { useI18n } from "vue-i18n"
import { Deliverer } from "@/interface/deliverer/Deliverer"
import { SelectOption } from "@/ui-elements/input/select/SelectComponent.vue"
import { useUtilsStore } from "@/store/Utils"
import { PAYMENT_STATUSES } from "@/interface/orders/Payment"
import { computed, ComputedRef, Ref } from "vue"
import { useDelivererStore } from "@/store/Deliverer"
import { isMethodShown } from "@/pages/orders/utils/usePaymentMethods"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"
import { useSettingsStore } from "@/store/Settings"

export const useOrderOptions = (
    order: Ref<Order>,
    initialPaymentMethod?: string
): {
    paymentMethodOptions: ComputedRef<
        { disabled: boolean; label: string; value: string }[]
    >
    statusOptions: SelectOption[]
    paymentStatusOptions: SelectOption[]
    delivererOptions: ComputedRef<
        | any[]
        | ({ label: string; value: null } | { label: string; value: number })[]
    >
    deliveryOptions: SelectOption[]
} => {
    const delivererStore = useDelivererStore()
    const utilsStore = useUtilsStore()
    const { t: translate } = useI18n()

    const statusOptions: SelectOption[] = ORDER_STATUSES_FOR_LABELS.map(
        (
            status:
                | "init"
                | "pending"
                | "pending-ready"
                | "enroute"
                | "delivered"
                | "pickedUp"
                | "served"
        ): { label: string; value: string } => {
            return {
                label: translate("var_order.status." + status),
                value: status,
            }
        }
    )

    const deliveryOptions: SelectOption[] = ORDER_DELIVERIES.map(
        (
            delivery: "deliver" | "dinein" | "pickup"
        ): { label: string; value: string } => {
            return {
                label: translate("var_order.delivery." + delivery),
                value: delivery,
            }
        }
    )

    const delivererOptions: ComputedRef<
        | any[]
        | ({ label: string; value: null } | { label: string; value: number })[]
    > = computed(
        ():
            | any[]
            | (
                  | { label: string; value: null }
                  | { label: string; value: number }
              )[] => {
            if (order.value.delivery !== "deliver") {
                return []
            }

            const options: { label: string; value: number }[] =
                delivererStore.deliverers.map(
                    (
                        deliverer: Deliverer
                    ): { label: string; value: number } => {
                        return {
                            value: deliverer.id,
                            label:
                                deliverer.first_name +
                                " " +
                                deliverer.last_name,
                        }
                    }
                )

            return [{ value: null, label: "None" }, ...options]
        }
    )

    const paymentStatusOptions: SelectOption[] = PAYMENT_STATUSES.map(
        (
            status: "init" | "pending" | "paid" | "refund" | "delivered"
        ): { label: string; value: string } => {
            return {
                label: translate("var_order.paymentStatus." + status),
                value: status,
            }
        }
    )

    const paymentMethodOptions: ComputedRef<
        { disabled: boolean; label: string; value: string }[]
    > = computed(() => {
        return Object.keys(utilsStore.paymentMethods)
            .filter(
                (methodKey: string) =>
                    isMethodShown(methodKey) ||
                    methodKey === initialPaymentMethod
            )
            .map(
                (
                    methodKey: string
                ): { disabled: boolean; label: string; value: string } => {
                    let label: string =
                        utilsStore.paymentMethods[methodKey].name
                    let isDisabled: boolean = false
                    if (order.value.payment2.amount) {
                        label =
                            utilsStore.paymentMethods[methodKey].name +
                            (order.value.payment2.method
                                ? " (" +
                                  useFormatCurrency(
                                      order.value.total -
                                          order.value.payment2.amount
                                  ) +
                                  ")"
                                : "")
                    }

                    if (
                        // OBV3-251: always allow pin with payment_pin_via_archive_allowed
                        useSettingsStore().settings
                            .payment_pin_via_archive_allowed === "0" &&
                        methodKey === PaymentMethod.PIN.valueOf() &&
                        !!Number(
                            useSettingsStore().settings.adyen_pin_active
                        ) &&
                        (useSettingsStore().settings.adyen_pin_poiid ||
                            localStorage.getItem("adyen_pin_poiid_local"))
                    ) {
                        isDisabled = true
                    }

                    return {
                        label: label,
                        value: methodKey,
                        disabled: isDisabled,
                    }
                }
            )
    })

    return {
        statusOptions,
        deliveryOptions,
        delivererOptions,
        paymentStatusOptions,
        paymentMethodOptions,
    }
}
