import {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosStatic,
} from "axios"
import { ApiError } from "@/interface/api/ApiError"
import { useAPIStore } from "@/store/API"
import { Router } from "vue-router"
import { RiceCooker } from "@/riceCooker/riceCooker"
import i18n from "@/i18n"
import { offlineModeStore } from "@/store/offlineMode"
import { networkIssuesStore } from "@/store/networkIssues"

export const setupAxios = (axios: AxiosStatic, router: Router) => {
    const APIStore = useAPIStore()

    axios.defaults.baseURL = process.env.VUE_APP_API_HOST_V3 + "/v1"
    axios.defaults.responseType = "json"

    const impersonatingClientID =
        localStorage.getItem("foodticketresellerimpersonation") || ""
    if (impersonatingClientID.length > 0) {
        axios.defaults.headers.common["foodticketresellerimpersonation"] =
            impersonatingClientID
    }

    axios.interceptors.request.use((request: AxiosRequestConfig) => {
        // add locale header
        if (!request.headers) {
            request.headers = {}
        }
        request.headers["Accept-Language"] = i18n.global.locale.value
        return request
    })
    const retries: { [key: string]: AxiosRequestConfig | null } = {}
    axios.interceptors.response.use(
        async (response: AxiosResponse) => {
            if (response?.config?.url) {
                retries[response.config.url] = null
                networkIssuesStore().removeIssue(response.config.url)
            }
            try {
                await RiceCooker.storeData(response.data)
            } catch (e) {
                console.error(e)
            }
            return response
        },
        async (error: AxiosError) => {
            console.log("SentryDevInfo: axios.ts onRejected error", error)
            // this is not an error we support
            if (
                !error.response ||
                !error.response.data ||
                !Object.prototype.hasOwnProperty.call(
                    error.response.data,
                    "error"
                )
            ) {
                // If the user manually cancelled a request
                if (axios.isCancel(error)) {
                    return Promise.reject(error)
                }
                // If we are not offline, and the error is unknown, then we attempt to retry the request
                if (!offlineModeStore().isOffline && !error.response) {
                    // If the request has not been retried yet, or it was successful in the past (unless it's the health-check endpoint) then we retry the request
                    if (
                        error?.config?.url &&
                        !error?.config?.url.includes("health-check") &&
                        !error?.config?.url.endsWith("/payment/pay") && // We should never initiate the same payment again
                        (!(error.config.url in retries) ||
                            retries[error.config.url] === null)
                    ) {
                        // Then we retry the request once
                        retries[error.config.url] = error.config
                        // We only retry if the apiHealthCheck is successful
                        return await offlineModeStore()
                            .apiHealthCheck()
                            .then(async () => {
                                console.log(
                                    "SentryDevInfo: health-check successful: axios.ts retrying request " +
                                        error.config?.url,
                                    error.config
                                )
                                return error.config
                                    ? await axios.request(error.config)
                                    : false
                            })
                            .catch(() => {
                                // If there are unhandled errors, and retrying did not resolve them, display a network issue notice
                                if (error?.config?.url) {
                                    networkIssuesStore().addIssue(
                                        error.config.url
                                    )
                                }
                                return Promise.reject(error)
                            })
                    } else if (
                        error?.config?.url &&
                        error?.config?.url.includes("health-check")
                    ) {
                        // If the request is the health-check endpoint, and it failed, we display a network issue notice
                        networkIssuesStore().addIssue(error.config.url)
                    }
                }
                return Promise.reject(error)
            }

            const errorCode: ApiError = error.response.data.error

            // automatically retrieve the new CSRF token and retry the request
            if (errorCode === ApiError.CSRF) {
                return axios.get("/csrf").then((r: AxiosResponse) => {
                    axios.defaults.headers.common["X-Csrf-Token"] =
                        r.data.data.token
                    error.config.headers = Object.assign(
                        {
                            ...(error.config.headers || {}),
                        },
                        {
                            "X-Csrf-Token": r.data.data.token,
                        }
                    )
                    return axios.request(error.config)
                })
            }

            if (errorCode === ApiError.INVALID_CREDENTIALS) {
                return Promise.reject(error)
            }

            if (errorCode === ApiError.UNAUTHORIZED) {
                // remove bearer token from localStorage, remove user data
                // and redirect to login page
                APIStore.setPreRedirectRoute(router.currentRoute.value.path)
                APIStore.logout()

                if (
                    router.currentRoute.value.name !== "login" &&
                    router.currentRoute.value.name !== "rc-update"
                ) {
                    router.push({ name: "login" })
                }
            }

            if (errorCode === ApiError.VALIDATION) {
                // perhaps we want to put the validation errors in to the store
                // so we can easily pass them to the form
            }

            // request the user to enter pincode
            if (errorCode === ApiError.PINCODE_REQUIRED) {
                return (
                    APIStore.setRequirePincode(true)
                        // pincode was successful, let's retry the request
                        .then((expirationDate: any) => {
                            APIStore.enableSudoMode(expirationDate)
                            return axios.request(error.config)
                        })
                        .catch(() => Promise.reject(error))
                )
            }
            if (errorCode === ApiError.READONLY) {
                console.warn("Orderbuddy is in archive mode.")
            }
            return Promise.reject(error)
        }
    )

    if (process.env.VUE_APP_ENV !== "production") {
        window.axios = axios
    }

    return axios
}
