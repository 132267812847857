import { RiceCooker } from "@/riceCooker/riceCooker"
import { useSettingsStore } from "@/store/Settings"

export default class NovacomApi {
    static sessionId: string
    static baseurl_data: string
    static baseurl_actions: string

    static host: string
    static port: number

    static setBaseUrls = (): void => {
        this.host =
            useSettingsStore().settings.novacom_apiIpAddress ?? "localhost"
        this.port = useSettingsStore().settings.novacom_apiIpPort ?? 5002

        this.baseurl_data =
            "http://" + this.host + ":" + this.port + "/api/v2/data/"
        this.baseurl_actions =
            "http://" + this.host + ":" + this.port + "/api/v2/actions/"
    }

    static getSessionId = (response: any) => {
        if (
            response.status === 200 &&
            Object.prototype.hasOwnProperty.call(response.headers, "set-cookie")
        ) {
            response.headers["set-cookie"].forEach((e: string) => {
                console.log("Cookie", e)
                const match = e.match(/sessionId=([^;]+)/)
                if (match) {
                    this.sessionId = match[1]
                }
            })
        }
    }

    static categories = () => {
        return this.novacom_fetch(
            "GET",
            this.sessionId,
            this.baseurl_data + "categories"
        )
    }

    static articles = () => {
        return this.novacom_fetch(
            "GET",
            this.sessionId,
            this.baseurl_data + "articles"
        )
    }

    static modifierGroups = () => {
        return this.novacom_fetch(
            "GET",
            this.sessionId,
            this.baseurl_data + "modifierGroups"
        )
    }

    static tables = () => {
        return this.novacom_fetch(
            "GET",
            this.sessionId,
            this.baseurl_data + "tables"
        )
    }

    static registerClient = async (id: any) => {
        return await this.novacom_fetch(
            "POST",
            "",
            this.baseurl_actions + "init/registerClient",
            { id: id }
        )
    }

    static authLogin = (id: any) => {
        return this.novacom_fetch(
            "POST",
            this.sessionId,
            this.baseurl_actions + "auth/login",
            { id: id }
        )
    }

    static openByName = async (id: any, order_id: any) => {
        return await this.novacom_fetch(
            "POST",
            this.sessionId,
            this.baseurl_actions + "tables/openByName/" + id,
            { tableDescription: { text: order_id } }
        )
    }

    static cancelOrder = async (id: any, order_id?: any) => {
        const openByName = await this.openByName(id, 0)
        return this.novacom_fetch(
            "POST",
            this.sessionId,
            this.baseurl_actions +
                "subtables/cancelOrder/" +
                openByName.data.subTables[0].id,
            {}
        )
    }

    static finalizeOrder = async (id: any) => {
        return await this.novacom_fetch(
            "POST",
            this.sessionId,
            this.baseurl_actions + "tables/finalizeOrder/" + id,
            {}
        )
    }

    static addOrderLines = async (id: any, data: any) => {
        return await this.novacom_fetch(
            "POST",
            this.sessionId,
            this.baseurl_actions + "orderLines/add/" + id,
            data
        )
    }

    static orderLines = async (data: any) => {
        return await this.novacom_fetch(
            "POST",
            this.sessionId,
            this.baseurl_actions + "pay/orderLines",
            data
        )
    }

    static subTables = async (data: any) => {
        return await this.novacom_fetch(
            "POST",
            this.sessionId,
            this.baseurl_actions + "pay/subTables",
            data
        )
    }

    static novacom_fetch = async (
        method: string,
        sessionid: string,
        url: any,
        data?: any
    ) => {
        console.log("--------\n_fetch", method, url, JSON.stringify(data))
        const options = {
            method: method,
            cache: "no-cache",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Cookie: "",
                "Content-Length": "",
            },
            body: {},
        }

        if (sessionid) {
            options.headers["Cookie"] = "sessionId=" + sessionid
        }

        if (method == "POST" && data) {
            options.body = JSON.stringify(data)
            options.headers["Content-Length"] = Object.keys(
                options.body
            ).length.toString()
        }

        console.log("_fetch options", JSON.stringify(options))

        if (RiceCooker.isPresent()) {
            const response = await RiceCooker.fetch(
                url,
                JSON.stringify(options)
            )
            if (response && /^2/.test(response.status)) {
                console.log(
                    "_fetch",
                    method,
                    url,
                    "response",
                    JSON.stringify(response)
                )
            } else {
                console.error(
                    "_fetch",
                    method,
                    url,
                    "response",
                    JSON.stringify(response)
                )
            }
            return response
        } else {
            console.error("_fetch requires ricecooker!")
            return {}
        }
    }

    static checkResult = (
        result: { status: string; message: string },
        tag?: string
    ) => {
        if (!result || !/^2/.test(result.status)) {
            if (result) result.message = tag + ": " + result.message
            return 0
        }
        return 1
    }

    static convertToAmount = (amount: any) => {
        // remove decimal sep by converting 9.20 to 920, 0.20 to 20, etc.
        return Number(
            parseFloat(amount).toFixed(2).replace(/^0?\./, "").replace(".", "")
        )
    }
}
