<template>
    <DeviceCheck>
        <template v-slot="{ isMobile, isTablet, isDesktop }">
            <div class="relative z-10" v-if="isMobile">
                <!-- Content for mobile -->
                <MobilePageHeader />
            </div>
            <div v-else-if="isTablet">
                <!-- Content for tablet -->
                <DesktopPageHeader />
            </div>
            <div v-else-if="isDesktop">
                <!-- Content for desktop -->
                <DesktopPageHeader />
            </div>

            <OrderAudioNotification
                v-if="
                    ordersStore.playSoundOnce ||
                    ordersStore.playSound ||
                    (ordersStore.autoOpened &&
                        ordersStore.showOrderId &&
                        !ordersStore.orderData(ordersStore.showOrderId)
                            ?.has_seen)
                "
                :once="ordersStore.playSoundOnce"
                :autoPrint="true"
                class="hidden"
            />
        </template>
    </DeviceCheck>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import DeviceCheck from "@/utils/responsive/DeviceCheck.vue"
import DesktopPageHeader from "@/ui-elements/responsive/desktop/header/DesktopPageHeader.vue"
import MobilePageHeader from "@/ui-elements/responsive/mobile/header/MobilePageHeader.vue"
import { useOrdersStore } from "@/store/Orders"
import OrderAudioNotification from "@/pages/orders/OrderAudioNotification.vue"

export default defineComponent({
    name: "PageHeader",
    components: {
        OrderAudioNotification,
        DeviceCheck,
        DesktopPageHeader,
        MobilePageHeader,
    },
    setup() {
        const ordersStore = useOrdersStore()

        return {
            ordersStore,
        }
    },
})
</script>
