import Pusher from "pusher-js"
import Echo from "laravel-echo"
import axios, { AxiosResponse } from "axios"
import { useUserStore } from "@/store/User"
import { dataHydration } from "@/services/DataHydrationService"
import { useOrdersStore } from "@/store/Orders"
import { useSettingsStore } from "@/store/Settings"
import { useTablesStore } from "@/store/Tables"

class WebsocketService {
    pusher: Pusher | undefined
    echo: Echo | undefined
    subscribed: boolean = false
    websocketChannel: any = undefined

    async start(): Promise<void> {
        if (this.isConnected()) {
            return
        }
        if (typeof this.pusher === "undefined" && dataHydration.isActive()) {
            this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY || "", {
                forceTLS: false,
                disableStats: true,
                enabledTransports: ["ws", "wss"],
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                authorizer: (channel) => {
                    return {
                        authorize: (socketId: string, callback: Function) => {
                            axios
                                .post("/broadcasting/auth", {
                                    socket_id: socketId,
                                    channel_name: channel.name,
                                })
                                .then((response: AxiosResponse<any>): void => {
                                    callback(null, response.data)
                                })
                                .catch((error) => {
                                    callback(error)
                                })
                        },
                    }
                },
            })
        }
        if (typeof this.echo === "undefined" && dataHydration.isActive()) {
            const options = {
                broadcaster: "pusher",
                key: process.env.VUE_APP_PUSHER_KEY,
                forceTLS: false,
                encrypted: true,
                disableStats: true,
                enabledTransports: ["ws", "wss"],
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            }
            this.echo = new Echo({
                ...options,
                client: this.pusher,
            })
        }

        if (
            typeof this.pusher !== "undefined" &&
            typeof this.echo !== "undefined"
        ) {
            const clientId = useUserStore().user.id

            this.pusher.connection.bind(
                "connecting",
                () => (this.subscribed = false)
            )

            const listenAndHydrateSingleInstancesOfAModule = (
                channel: any,
                eventPrefix: string,
                module: string,
                store: any
            ): void => {
                if (eventPrefix === "table") {
                    channel.listen(".table.created", (event: any) =>
                        useTablesStore().objectCreated(event?.id)
                    )
                }

                if (eventPrefix === "order") {
                    channel.listen(".order.placed", (event: any) =>
                        useOrdersStore().objectPlaced(event?.id)
                    )
                }

                channel
                    .listen(`.${eventPrefix}.updated`, (event: any) =>
                        store.objectUpdated(event?.id)
                    )
                    .listen(`.${eventPrefix}.deleted`, (event: any) =>
                        store.objectDeleted(event?.id)
                    )
            }

            const listenAndRehydrateModule = async (
                channel: any,
                eventPrefix: string,
                module: string
            ): Promise<void> => {
                channel
                    .listen(`.${eventPrefix}.created`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
                    .listen(`.${eventPrefix}.updated`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
                    .listen(`.${eventPrefix}.deleted`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
            }

            const listenAndRehydrateSettings = async (
                channel: any
            ): Promise<void> => {
                const eventPrefix: string = "setting"
                const module: string = "settings"
                const store = useSettingsStore()

                channel
                    .listen(
                        `.${eventPrefix}.created`,
                        async (): Promise<void> => {
                            await dataHydration.hydrateModule(
                                module,
                                false,
                                2000
                            )
                        }
                    )
                    .listen(
                        `.${eventPrefix}.updated`,
                        async (): Promise<void> => {
                            await dataHydration.hydrateModule(
                                module,
                                false,
                                2000
                            )
                        }
                    )
                    .listen(`.${eventPrefix}.deleted`, (event: any) =>
                        store.removeSetting(event?.id)
                    )
            }

            const channel = this.echo
                .private(`client.${clientId}`)
                .subscribed((): boolean => (this.subscribed = true))

            this.websocketChannel = channel

            await listenAndRehydrateModule(channel, "user", "user")
            await listenAndRehydrateModule(channel, "deliverer", "deliverers")
            await listenAndRehydrateModule(channel, "discount", "discounts")
            await listenAndRehydrateModule(
                channel,
                "discountproduct",
                "discounts"
            )
            await listenAndRehydrateModule(
                channel,
                "discountproductproduct",
                "discounts"
            )
            // listenAndRehydrateModule(channel, "order", "orders")
            listenAndHydrateSingleInstancesOfAModule(
                channel,
                "order",
                "orders",
                useOrdersStore()
            )
            listenAndHydrateSingleInstancesOfAModule(
                channel,
                "table",
                "tables",
                useTablesStore()
            )
            await listenAndRehydrateModule(channel, "category", "posMenu")
            await listenAndRehydrateModule(channel, "product", "posMenu")
            await listenAndRehydrateModule(channel, "extra", "posMenu")
            await listenAndRehydrateModule(channel, "item", "posMenu")
            await listenAndRehydrateModule(channel, "clientzipcode", "zipcodes")
            await listenAndRehydrateModule(channel, "printer", "printers")
            await listenAndRehydrateSettings(channel)
            await listenAndRehydrateModule(
                channel,
                "clientopen",
                "openingHours"
            )
            await listenAndRehydrateModule(
                channel,
                "clientclosed",
                "closingHours"
            )
            await listenAndRehydrateModule(channel, "device", "localIps")
        }
    }

    stop(): void {
        this.pusher?.disconnect()
        this.echo?.disconnect()
        this.pusher = undefined
        this.echo = undefined
        this.subscribed = false
    }

    isConnected(): boolean {
        return this.getConnectionState() === "connected" && this.subscribed
    }

    getConnectionState(): string {
        return this.pusher?.connection.state || "offline (stopped)"
    }
}

export const websocket: WebsocketService = new WebsocketService()
