<template>
    <div
        class="order-tile"
        @mousedown="click"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
        :class="{ highlight, delivered, alert }"
        ref="tile"
    >
        <IconBase
            icon="circle-filled"
            class="not-seen-order animate-pulse-object"
            v-if="!order.has_seen"
        />
        <OrderSource
            :order="order"
            :show-remarks="showRemarks"
            :show-deliverer="showDeliverer"
        />
        <div class="order-and-payment">
            <OrderDetails
                :isForMap="isForMap"
                :order="order"
                :delivery-type="deliveryType"
                :showButtons="showButtons"
                :show-remarks="showRemarks"
                :show-deliverer="showDeliverer"
            />
            <KitchenDetails
                :order="order"
                :delivery-type="deliveryType"
                :showButtons="showButtons"
                @status="updateStatus($event)"
                :show-remarks="showRemarks"
                :show-deliverer="showDeliverer"
            />
            <template v-if="showRemarks">
                <div class="divider"></div>
                <div class="remarks-text">
                    <div class="remarks">{{ order.remarks }}</div>
                </div>
            </template>
            <template v-if="showDeliverer">
                <div class="divider"></div>
                <div class="deliverer-text">
                    <div class="city">{{ order.customer?.address?.city }}</div>
                    <div class="deliverer">
                        {{
                            order.deliverer?.first_name +
                            " " +
                            order.deliverer?.last_name
                        }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import { getDeliveryType } from "@/pages/orders/order/utils/getDeliveryType"
import IconBase from "@/ui-elements/IconBase.vue"
import { useI18n } from "vue-i18n"
import OrderSource from "@/pages/orders/order/components/OrderSource.vue"
import OrderDetails from "@/pages/orders/order/components/OrderDetails.vue"
import KitchenDetails from "@/pages/orders/order/components/KitchenDetails.vue"
import { Order, OrderStatusType } from "@/interface/orders/Order"
import { useSettingsStore } from "@/store/Settings"
import { useOrdersStore } from "@/store/Orders"
import { onLongPress } from "@vueuse/core"
import { useDoubleClick } from "@/utils/useDoubleClick"
import axios from "axios"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { Interval } from "luxon"
import { isInStore } from "@/pages/orders/order/utils/isInStore"
import { updateOrderStatus } from "@/pages/orders/order/utils/updateStatus"
export default defineComponent({
    name: "OrderTile",
    components: {
        KitchenDetails,
        OrderDetails,
        IconBase,
        OrderSource,
    },
    props: {
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
        isForMap: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { t: translate } = useI18n()
        const ordersStore = useOrdersStore()

        const deliveryType = computed(() =>
            getDeliveryType(props.order.delivery)
        )
        const showButtons = computed(
            () =>
                !!Number(useSettingsStore().settings.ob_show_buttons) &&
                isInStore(props.order)
        )
        const showRemarks = computed(
            () =>
                !!props.order.remarks &&
                !!Number(useSettingsStore().settings.orders_show_remarks)
        )
        const showDeliverer = computed(
            () =>
                props.order.delivery === "deliver" &&
                !!Number(useSettingsStore().settings.ob_show_deliverer) &&
                !!props.order.deliverer
        )

        const delivered = computed(
            () =>
                props.order.status === "delivered" ||
                props.order.status === "enroute"
        )

        const save = (newOrder: Partial<Order>) =>
            axios.patch("client/orders/" + props.order.id, newOrder)
        const saveOrder = async (newOrder: Partial<Order>) => {
            let success = false
            save(newOrder)
                .then((response) => {
                    success = true
                    useOrdersStore().saveOrder(response.data.data)
                })
                .catch(() => {
                    setToastNotification(
                        translate("something_went_wrong"),
                        translate("please_try_again"),
                        "danger"
                    )
                })
            return success
        }
        const updateStatus = async (status: OrderStatusType) => {
            await updateOrderStatus(status, props.order, saveOrder)
        }
        const nextStatus = computed(() => {
            return {
                init: "pending",
                pending: "pending-ready",
                "pending-ready":
                    props.order.delivery === "deliver"
                        ? "enroute"
                        : "delivered",
                enroute: "delivered",
            } as Record<OrderStatusType, OrderStatusType>
        })
        const changeStatus = computed(
            () =>
                ({
                    status_next: (status: OrderStatusType) =>
                        nextStatus.value[status],
                    status_pending: () => "pending",
                    status_enroute:
                        props.order.delivery === "deliver"
                            ? () => "enroute"
                            : () => "delivered",
                    status_delivered: () => "delivered",
                } as Record<
                    string,
                    (status: OrderStatusType) => OrderStatusType
                >)
        )

        const tile = ref()
        let timeout = 0
        const clickHandler = () => {
            timeout = window.setTimeout(() => {
                ordersStore.openOrderModal(props.order.id)
            }, 200)
        }
        const doubleClickHandler = () => {
            if (
                useSettingsStore().settings.ob_order_dblclick === "status_none"
            ) {
                return
            }

            const status = changeStatus.value[
                useSettingsStore().settings.ob_order_dblclick
            ](props.order.status)
            updateStatus(status)
        }
        const holdHandler = () => {
            if (
                useSettingsStore().settings.ob_order_clickhold === "status_none"
            ) {
                return
            }

            window.clearTimeout(timeout)
            const status = changeStatus.value[
                useSettingsStore().settings.ob_order_clickhold
            ](props.order.status)
            updateStatus(status)
        }
        const click = useDoubleClick(clickHandler, doubleClickHandler, 250)
        onLongPress(tile, holdHandler, { delay: 300 })

        const alert = computed(() => {
            if (
                props.order.time_set &&
                ["init", "pending"].includes(props.order.status)
            ) {
                const alertTime =
                    parseInt(useSettingsStore().settings.alert_minutes) === 0
                        ? null
                        : parseInt(useSettingsStore().settings.alert_minutes)

                if (!alertTime) {
                    return false
                }

                const currentTime = new Date()
                const dueTime = (() => {
                    const array = props.order.time_set
                        ? props.order.time_set
                              .split(":")
                              .map((time) => parseInt(time))
                        : []
                    const setTime = new Date()
                    if (array.length) {
                        setTime.setHours(array[0], array[1])
                    }
                    return setTime
                })()
                const difference = Interval.fromDateTimes(currentTime, dueTime)
                if (
                    !difference.isValid ||
                    difference.length("minutes") < alertTime
                ) {
                    return true
                }
            }
            return false
        })

        return {
            translate,
            delivered,
            deliveryType,
            ordersStore,
            tile,
            click,
            alert,
            showButtons,
            updateStatus,
            showRemarks,
            showDeliverer,
        }
    },
    emits: ["mouseover", "mouseleave"],
})
</script>

<style scoped lang="scss">
.order-tile {
    min-width: 22rem;
    background: $light-gray;
    border-radius: $radius;
    position: relative;
    display: grid;
    grid-template-columns: 5rem minmax(0, 1fr);

    &:hover {
        cursor: pointer;
    }

    &.highlight {
        box-shadow: 0 0 0 2px $primary-color;
    }

    &.delivered {
        opacity: 0.6;
    }
    &.alert {
        animation: alert 0.5s infinite;
    }
    &.updating {
        background: red;
        transform: scale(0.99, 0.98);
    }

    .divider-margin {
        margin-bottom: $margin-m;
    }

    .not-seen-order {
        position: absolute;
        top: -0.4rem;
        right: -0.4rem;
        border-radius: 50%;

        &.icon-circle-filled {
            color: $primary-color;
            &::before {
                margin: 0 -0.5px 0 -0.5px;
            }
        }
    }
    :deep(.reference-number) {
        font-size: 1.55rem;
        color: $gray;
        font-weight: $font-weight-medium;
    }

    .order-and-payment {
        width: 100%;
        padding: $padding-m;

        .divider {
            border-top: 0.5px solid #c3c4ca;
        }
        .remarks-text {
            padding: $padding-m 0 $padding-s 0;

            .remarks {
                font-size: $font-size-nav;
                color: $darker-gray;
            }
        }
        .deliverer-text {
            padding: $padding-m 0 $padding-s 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
</style>
