import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"
import { useSettingsStore } from "@/store/Settings"

export const getPaymentMethodsEditable = (method: string): boolean => {
    // If paid with Adyen, it can't be changed anymore
    if (
        method === PaymentMethod.PIN.valueOf() &&
        !!Number(useSettingsStore().settings.adyen_pin_active) &&
        (useSettingsStore().settings.adyen_pin_poiid ||
            localStorage.getItem("adyen_pin_poiid_local"))
    ) {
        return false
    }

    return [
        PaymentMethod.CASH.valueOf(),
        PaymentMethod.INVOICE.valueOf(),
        PaymentMethod.PIN.valueOf(),
        PaymentMethod.ONLINE.valueOf(),
    ].includes(method)
}

// Online option is not shown, it's only editable when originally present
export const isMethodShown = (method: string) =>
    [
        PaymentMethod.CASH.valueOf(),
        PaymentMethod.INVOICE.valueOf(),
        PaymentMethod.PIN.valueOf(),
    ].includes(method)
