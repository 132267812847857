import { defineStore } from "pinia"
import axios, { AxiosResponse } from "axios"
import { ReceiptLayout } from "@/interface/settings/printers/Receiptlayout"
import { getLocalPrinterStorageKey } from "@/pages/settings/general/printers/receipt-layouts/utils/useLocalPrinters"
import { Order } from "@/interface/orders/Order"
import { DateTime } from "luxon"
import { multiLocationStore } from "@/store/MultiLocation"
import { UnwrapRef } from "vue"

export type PrintType = "normal" | "customer"

export type PrintersStore = {
    printers: ReceiptLayout[]
    printedOrders: Array<{
        orderId: Order["id"]
        timestamp: number
        printType: PrintType
    }>
}

export const usePrintersStore = defineStore({
    id: "printers",
    state: (): PrintersStore => <PrintersStore>({
            printers: [],
            printedOrders: [],
        } as PrintersStore),
    getters: {
        printerData:
            (state) =>
            (printerId: number): ReceiptLayout | undefined => {
                return state.printers.find(
                    (printer: ReceiptLayout) => printer.id === printerId
                )
            },
        defaultPrinter:
            (state) =>
            (locationId?: number): ReceiptLayout | undefined => {
                if (
                    multiLocationStore().isActive &&
                    locationId &&
                    multiLocationStore().useLocationPrinters
                ) {
                    return (
                        multiLocationStore().printers(locationId) ?? []
                    ).find((printer: ReceiptLayout) => printer.is_default)
                } else {
                    return state.printers.find(
                        (printer: ReceiptLayout) => printer.is_default
                    )
                }
            },
        isOrderAlreadyPrinted:
            (state) =>
            (orderId: Order["id"], type: PrintType = "normal"): boolean =>
                !!state.printedOrders.find(
                    (printedOrder) =>
                        printedOrder.orderId === orderId &&
                        printedOrder.printType === type
                ),
    },
    actions: {
        markOrderAsPrinted(
            orderId: Order["id"],
            type: PrintType = "normal"
        ): void {
            if (this.isOrderAlreadyPrinted(orderId, type)) {
                const index = this.printedOrders.findIndex(
                    (printedOrder) =>
                        printedOrder.orderId === orderId &&
                        printedOrder.printType === type
                )

                if (index) {
                    this.printedOrders[index].timestamp =
                        DateTime.now().toUnixInteger()
                }
            } else {
                this.printedOrders.push({
                    orderId: orderId,
                    timestamp: DateTime.now().toUnixInteger(),
                    printType: type,
                })
            }
        },
        async fetchPrinters(): Promise<boolean> {
            try {
                const response: AxiosResponse<any> = await axios.get(
                    "client/printers/selection"
                )
                this.printers = response.data.data
                this.applyLocalIds()

                if (multiLocationStore().isActive) {
                    await multiLocationStore().fetchPrinters()
                }
                return true
            } catch (e) {
                return false
            }
        },
        applyLocalIds() {
            this.printers.forEach(
                (printer: UnwrapRef<ReceiptLayout>, index: number): void => {
                    const localKey: string = getLocalPrinterStorageKey(
                        printer.id
                    )
                    const fromLocalStorage: string | null =
                        localStorage.getItem(localKey)
                    if (fromLocalStorage) {
                        this.printers[index].printer = fromLocalStorage
                    }
                }
            )
        },
        selectPrintersForReceipt(
            printers: Array<ReceiptLayout> | undefined,
            receiptType: string
        ): Array<ReceiptLayout> {
            return (
                (printers ?? []).filter(
                    (printer: ReceiptLayout) =>
                        printer.is_available &&
                        printer.in_use.find(
                            (value: {
                                type_of_print: string
                                quantity: number
                            }) =>
                                value.type_of_print === receiptType &&
                                value.quantity > 0
                        )
                ) ?? []
            )
        },
        selectQuantityForReceipt(
            printer: ReceiptLayout,
            receiptType: string
        ): number {
            const inUse:
                | {
                      type_of_print: string
                      quantity: number
                  }
                | undefined = printer.in_use.find(
                (value: { type_of_print: string; quantity: number }) =>
                    value.type_of_print === receiptType
            )

            return (inUse ?? {}).quantity ?? 0
        },
        removeFromAlreadyPrintedQueue(order: Order): void {
            const index: number = this.printedOrders.findIndex(
                (printedOrder): boolean => printedOrder.orderId === order.id
            )

            if (index > 0) {
                this.printedOrders.splice(index, 1)
            }
        },
    },
})
