import { CartDiscount, DiscountType } from "@/interface/Cart"
import { OrderProduct } from "@/interface/orders/OrderProduct"
import { multiLocationStore } from "@/store/MultiLocation"
import { paramsSerializer } from "@/utils/api/paramsSerializer"
import { defineStore } from "pinia"
import { Order } from "@/interface/orders/Order"
import axios, { AxiosResponse } from "axios"
import {
    DataFormatter,
    defaultFormatter,
} from "@/pages/orders/utils/useOrdersSorting"
import { useSettingsStore } from "@/store/Settings"
import { printOrder } from "@/services/printer/PrinterService"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { DateTime } from "luxon"
import { cloneDeep } from "lodash"
import { getDefaultCustomer } from "@/interface/Customers/Customer"
import { CartState, useCartStore } from "@/store/cart/Cart"
import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import i18n from "@/i18n"
import { isInStore } from "@/pages/orders/order/utils/isInStore"
import { useWorkingDay } from "@/utils/useWorkingDay"
import { usePOSStore } from "@/store/POS"
import { UnwrapRef } from "vue"

const ordersScreenExceptionSources = ["online", "counter"]
const numberBuddySources = ["online", "counter", "kiosk"]

// @ts-ignore
export const useOrdersStore = defineStore({
    id: "orders",
    state: () =>
        ({
            orders: [],
            kitchenOrders: [],
            futureOrderCount: 0,
            loading: false,
            dataReady: false,
            showOrderId: null,
            showTimeImmediately: false,
            playSoundOnce: false,
            playSound: false,
            autoOpened: false,
            newOrderIds: [],
            show: {
                internal: false,
                external: false,
            },
            updatedAt: DateTime.now().toUnixInteger(),
            kitchenOrdersLoaded: false,
        } as {
            orders: Order[]
            kitchenOrders: Order[]
            futureOrderCount: number
            playSoundOnce: boolean
            playSound: boolean
            loading: boolean
            dataReady: boolean
            showOrderId: number | null
            showTimeImmediately: boolean
            autoOpened: boolean
            newOrderIds: number[]
            show: {
                internal: boolean
                external: boolean
            }
            updatedAt: number
            kitchenOrdersLoaded: boolean
        }),
    getters: {
        orderData:
            (state) =>
            (orderId: number): Order | undefined => {
                return state.orders.find(
                    (order: Order): boolean => order.id === orderId
                )
            },
        isWaiterBuddy: () => (order: Order) =>
            order?.source?.type === "counter" &&
            order?.source?.info?.match("ios|android"),
        ordersScreenOrders: (state) => {
            // All orders should be shown if one of these settings is true
            const showAllOrders: number =
                Number(useSettingsStore().settings.ob_show_always) ||
                Number(useSettingsStore().settings.numberbuddy_show_pos_orders)

            return state.orders.filter((order): boolean => {
                // Because of the new way we handle data hydration, we need to check this in the frontend too.
                // Because (created) events for future orders are also broadcasted on the websocket.
                const workingDay = useWorkingDay()
                if (
                    !(
                        DateTime.fromJSDate(new Date(order.created_at), {
                            zone: useSettingsStore().getTimeZone,
                        }) >= workingDay.startOfWorkingDay.value &&
                        DateTime.fromJSDate(new Date(order.created_at), {
                            zone: useSettingsStore().getTimeZone,
                        }) <= workingDay.endOfWorkingDay.value
                    )
                ) {
                    return false
                }

                if (order.payment.status !== "paid") {
                    return false
                }

                if (order.payment.result === "CREDIT") {
                    return false
                }

                if (showAllOrders) {
                    // We don’t want to show QR table orders, so we can't simply say 'show all'
                    // We still need to do some filtering
                    return (
                        !ordersScreenExceptionSources.includes(
                            order.source.type
                        ) || !!order.delivery
                    )
                } else {
                    if (order.table_id) {
                        return false
                    }
                }

                return (
                    !ordersScreenExceptionSources.includes(order.source.type) ||
                    (!!order.delivery && !!order.time_set)
                )
            })
        },
        filteredOrders(): Order[] {
            const orders = this.ordersScreenOrders

            // const filtered =
            return defaultFormatter(
                orders.filter((order): boolean => {
                    if (!this.show.internal && !this.show.external) {
                        return true
                    }
                    if (isInStore(order)) {
                        return this.show.internal
                    } else {
                        return this.show.external
                    }
                })
            )
        },
        numberBuddyOrders: (state): Order[] => {
            return state.orders.filter(
                (order: Order): boolean =>
                    numberBuddySources.includes(order.source.type) &&
                    order.delivery != "deliver"
            )
        },
        kitchenScreenOrders: (state): Order[] => {
            const direct: boolean =
                useSettingsStore().settings.kitchenscreen_send_when === "direct"
            const directSources: string[] | undefined =
                useSettingsStore().settings.kitchenscreen_send_when_direct?.split(
                    " "
                )
            const inDirectSetting = (source: string): boolean =>
                (directSources ?? []).includes(source)

            const isDirect = (order: Order): boolean => {
                if (order.source.type === "online") {
                    return (
                        // Source info: qr, qr_table
                        inDirectSetting(order.source.info) ||
                        ((order.source.info === "" ||
                            order.source.info === null) &&
                            inDirectSetting("online")) ||
                        // Direct setting: app
                        (["ios", "android"].includes(order.source.info) &&
                            inDirectSetting("app"))
                    )
                }
                // Source type: counter, kiosk, bt, de, hu, th, tb, ue
                return inDirectSetting(order.source.type)
            }

            return state.kitchenOrders.filter(
                (order: Order): boolean =>
                    order.status === "pending" ||
                    (direct && order.status === "init" && isDirect(order))
            )
        },
        unseenOrders: (state): Order[] =>
            state.orders.length
                ? state.orders.filter(
                      (order: Order): boolean => !order.has_seen
                  )
                : [],
        fetchOrderUrl:
            () =>
            (
                orderId: number | null = null,
                extraOrderFields: Array<string> = [],
                includes: Array<string> = [],
                customerFields: Array<string> = [],
                needMultiLocationAggregation: boolean = false,
                isForKitchen: boolean = false
            ) => {
                const orderFields: string[] = [
                    "id",
                    "client_id",
                    "nr",
                    "refnr",
                    "source",
                    "address",
                    "has_seen",
                    "is_new_customer",
                    "table_name",
                    "is_v3",
                    "payment",
                    "created_at",
                    "time",
                    "time_set",
                    "total",
                    "total_rounded",
                    "status",
                    "delivery",
                    "distance",
                    "duration",
                    "payment",
                    "payment2",
                    "vat_rates",
                    "paid_at",
                ]

                let url: string =
                    "client/orders" + (isForKitchen ? "/kitchen-screen" : "")
                if (orderId) {
                    url += `/${orderId}`
                }
                url += `?fields[orders]=${orderFields
                    .concat(extraOrderFields)
                    .join(",")}`

                if (includes.length) {
                    url += `&include=${includes.join(",")}`
                }

                if (customerFields.length) {
                    url += `&fields[customer]=${customerFields}`
                }

                if (needMultiLocationAggregation) {
                    url += `&${multiLocationStore().aggregateParameter()}`
                }

                return url
            },
    },
    actions: {
        _log(
            message: string,
            isError: boolean = false,
            stackTrace: any = null
        ) {
            if (window.ob.hasDebugModule("OrderStore")) {
                if (!isError) {
                    return console.log(`[OrderStore] ${message}`)
                }

                return console.error(`[OrderStore] ${message}`, stackTrace)
            }
        },
        getOrderLetter(orderId: Order["id"]) {
            const letters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
            let index: number = this.orders.findIndex(
                (order: Order): boolean => order.id === orderId
            )
            let result: string = ""

            while (index >= 0) {
                result = letters[index % 26] + result
                index = Math.floor(index / 26) - 1
            }

            return result
        },
        getOrderLabel(order: Order): string {
            const label: number = order.refnr ?? order.nr ?? order.id

            // table name
            if (order.table_id && order.table_name) {
                return order.table_name
            }

            if (order.source.info === "qr") {
                return `QR Skip the line: ${label}`
            }

            if (order.source.type === "kiosk") {
                return `Kiosk: ${label}`
            }

            if (
                ["ue", "de"].includes(order.source.type) &&
                !order.address.street
            ) {
                return `#${order.source.id}`
            }

            if (
                (Boolean(Number(useSettingsStore().settings.ob_show_name)) ||
                    order.delivery === "pickup") &&
                order.address.first_name
            ) {
                return `${
                    order.address.company ? order.address.company + " " : ""
                }${order.address.first_name} ${order.address.last_name ?? ""}`
            }

            if (order.address.street) {
                return `${order.address.street} ${order.address.street_number}`
            }

            return `# ${label}`
        },
        async fetchOrders(
            dataFormatter: DataFormatter = defaultFormatter
        ): Promise<boolean> {
            const { t: translate } = i18n.global
            try {
                if (!this.dataReady) {
                    this.loading = true
                }

                let url: string = this.fetchOrderUrl(
                    null,
                    [],
                    ["deliverer", "customer"],
                    ["email", "phone", "last_name"],
                    true
                )

                if (this.dataReady && this.updatedAt) {
                    url += `&filter[updated_after]=${this.updatedAt}`
                }

                url += `&need_future_count`

                const response = await axios.get(url)

                response.data.data.map((order: Order) => {
                    order.label = this.getOrderLabel(order)

                    return order
                })

                // This is the initial fetch. This is the only place where we 'mass' assign the orders to the state.
                if (!this.dataReady) {
                    this.orders = dataFormatter(response.data.data)
                    await this.loadOrders()
                    this.dataReady = true
                } else {
                    const apiOrders = response.data.data
                    const updatedOrders = apiOrders.filter(
                        (order: Order): boolean =>
                            this.orders.findIndex(
                                (existingOrder: UnwrapRef<Order>): boolean =>
                                    existingOrder.id === order.id
                            ) >= 0
                    )
                    const newOrders = apiOrders.filter(
                        (order: Order): boolean =>
                            this.orders.findIndex(
                                (existingOrder: UnwrapRef<Order>): boolean =>
                                    existingOrder.id === order.id
                            ) === -1
                    )

                    const updatedOrderIds = updatedOrders?.map(
                        (order: Order) => order.id
                    )
                    await this.loadOrders(updatedOrderIds)

                    this.orders = dataFormatter(
                        cloneDeep(this.orders).concat(newOrders)
                    )
                }

                this.futureOrderCount =
                    response.data?.meta?.future_orders_count || 0

                await this.checkNewOrders()

                this.updatedAt = DateTime.now().toUnixInteger()

                return true
            } catch (e) {
                console.error(e)
                setToastNotification(
                    translate("something_went_wrong"),
                    translate("orders_fetch_failed"),
                    "danger"
                )
                return false
            } finally {
                this.loading = false
            }
        },
        async fetchKitchenOrders(
            dataFormatter: DataFormatter = defaultFormatter
        ): Promise<boolean> {
            try {
                if (!this.dataReady) {
                    this.loading = true
                }

                const url: string = this.fetchOrderUrl(
                    null,
                    [],
                    ["products"],
                    [],
                    true,
                    true
                )

                const response: AxiosResponse<any> = await axios.get(url)

                this.kitchenOrders = dataFormatter(response.data.data)

                return true
            } catch (e) {
                console.log(e)
                return false
            } finally {
                this.loading = false
                this.kitchenOrdersLoaded = true
            }
        },
        async fetchObject(objectId: number): Promise<Order> {
            const response: AxiosResponse<any> = await axios.get(
                `client/orders/${objectId}`,
                {
                    params: {
                        main_location_id: multiLocationStore().isActive
                            ? multiLocationStore().main_location_id
                            : 0,
                    },
                    paramsSerializer: paramsSerializer,
                }
            )

            if (!response) {
                this._log(`cannot fetch order with id #${objectId}`)
                throw new Error("cannot fetch order!")
            }

            return response.data.data as Order
        },
        async objectPlaced(objectId: number): Promise<boolean> {
            try {
                const order: Order = await this.fetchObject(objectId)
                const settingsStore = useSettingsStore()
                const workingDay = useWorkingDay()
                const existingObject = this.orders.find(
                    (order: Order) => order.id == objectId
                )
                const masterSlave = useMasterSlave()

                this._log(`Received order with id #${order.id}.`, false, order)

                const isWaiterBuddy: boolean =
                    order.source.type === "counter" &&
                    (order.source.info?.match("ios|android") ?? []).length > 0
                const isKiosk: boolean = order.source.type === "kiosk"

                // Handle WaiterBuddy Table Orders
                if (
                    order.table_id &&
                    isWaiterBuddy &&
                    masterSlave.isMaster.value
                ) {
                    this._log(
                        `Printing WaiterBuddy order #${order.id} (table: ${order.table_id})`
                    )
                    await printOrder(order, {
                        printCustomerReceipts: true,
                        force: true,
                    })
                    await this.acceptOrder(objectId)
                    return true
                }

                if (existingObject) {
                    await this.objectUpdated(objectId)
                    this._log(
                        `Order with id #${objectId} was sent as created, but already exists. Updated the existing order instead`
                    )
                    return true
                }

                if (
                    !(
                        DateTime.fromJSDate(new Date(order.created_at), {
                            zone: settingsStore.getTimeZone,
                        }) >= workingDay.startOfWorkingDay.value &&
                        DateTime.fromJSDate(new Date(order.created_at), {
                            zone: settingsStore.getTimeZone,
                        }) <= workingDay.endOfWorkingDay.value
                    ) &&
                    !isWaiterBuddy
                ) {
                    this._log(
                        `Received an order that is not for the current working day. Order #${order.id} is not processed`
                    )
                    return false
                }
                const settings = useSettingsStore().settings

                if (!isWaiterBuddy && order.delivery !== "dinein") {
                    order.label = this.getOrderLabel(order)
                    order.is_complete = true

                    // Add new orders that came in with Hydration to the front of the array
                    const saved: number = this.orders.unshift(order)

                    if (!saved) {
                        this._log("Order is not pushed to Order Store")
                        return false
                    }

                    this.orders = defaultFormatter(cloneDeep(this.orders))
                }

                if (
                    !masterSlave.isMaster.value ||
                    window.location.href.includes("/customer-screen")
                ) {
                    this._log(
                        `This screen is not Master or is a Customer Screen. Not printing order #${order.id}`
                    )
                    return true
                }

                if (
                    isKiosk ||
                    isWaiterBuddy ||
                    (settings.print_auto !== "0" &&
                        settings.print_when === "direct" &&
                        !order.has_seen)
                ) {
                    try {
                        this.playSoundOnce = true
                        await printOrder(order)
                        await this.acceptOrder(objectId)
                        return true
                    } catch (e) {
                        this._log(
                            `Could not auto accept order #${objectId}`,
                            true,
                            e
                        )
                    }
                }

                if (order.has_seen || order.source.type === "counter") {
                    return true
                }

                if (!this.showOrderId && order) {
                    this.playSound = true
                    await this.openOrderModal(
                        Number(objectId),
                        true,
                        settings.show_popup === "time"
                    )
                } else {
                    this.playSound = true
                }

                return true
            } catch (e) {
                return false
            }
        },
        async objectUpdated(objectId: number) {
            const order: Order = await this.fetchObject(objectId)
            const existingObject: UnwrapRef<Order> | undefined =
                this.orders.find(
                    (order: Order): boolean => order.id == objectId
                )

            order.is_complete = true
            order.label = this.getOrderLabel(order)

            if (existingObject) {
                this.orders[this.orders.indexOf(existingObject)] = order
            }

            this.orders = defaultFormatter(cloneDeep(this.orders))

            const existingKitchenObject: UnwrapRef<Order> | undefined =
                this.kitchenOrders.find(
                    (order: Order): boolean => order.id == objectId
                )

            if (existingKitchenObject) {
                this.kitchenOrders[
                    this.kitchenOrders.indexOf(existingKitchenObject)
                ] = order
            }

            // For an ongoing table order, the object placed is not fired,
            // so we need to print the receipt here
            const isWaiterBuddy: boolean =
                order.source.type === "counter" &&
                (order.source.info?.match("ios|android") ?? []).length > 0

            if (
                isWaiterBuddy &&
                useMasterSlave().isMaster.value &&
                !order.has_seen &&
                // When the order is paid for, there will be an order placed event
                order.payment.status !== "paid"
            ) {
                this._log(
                    `Printing WaiterBuddy order #${order.id} (table: ${order.table_id})`
                )
                await this.acceptOrder(order.id)
                await printOrder(order)
            }
        },
        objectDeleted(orderId: number) {
            this.orders = this.orders.filter(
                (order: Order) => order.id != orderId
            )
        },
        updateOrder(orderId: number, data: Partial<Order>) {
            const existingOrder = this.orders.findIndex(
                (item) => item.id === orderId
            )
            if (existingOrder >= 0) {
                this.orders[existingOrder] = Object.assign(
                    this.orders[existingOrder],
                    data
                )
            }
            this.orders = defaultFormatter(this.orders)
        },
        saveOrder(order: Order): void {
            const existingOrder: number = this.orders.findIndex(
                (item: UnwrapRef<Order>): boolean => item.id === order.id
            )
            if (existingOrder >= 0) {
                this.orders[existingOrder] = order
            } else {
                this.orders.push(order)
            }
            this.orders = defaultFormatter(this.orders)
        },
        async openOrderModal(
            orderId: number,
            autoOpened: boolean = false,
            showTimeImmediately: boolean = false
        ): Promise<void> {
            this.loading = true

            if (!this.orderData(orderId)?.is_complete) {
                await this.objectUpdated(orderId)
            }

            this.showOrderId = orderId
            this.autoOpened = autoOpened
            this.showTimeImmediately = showTimeImmediately
            this.loading = false
        },
        async loadOrders(
            updatedOrderIds: Array<Number> | null = null
        ): Promise<void> {
            return this.orders.forEach((order: Order): void => {
                if (updatedOrderIds?.includes(order.id) || !order.is_complete) {
                    this.objectUpdated(order.id)
                }
            })
        },
        async checkNewOrders(): Promise<void> {
            const masterSlave = useMasterSlave()
            this._log("checkNewOrder: start")

            if (
                window.location.href.includes("/customer-screen") ||
                masterSlave.isSlave.value
            ) {
                return
            }

            const { settings } = useSettingsStore()

            if (Number(settings.hide_popup)) {
                this._log("checkNewOrder: hide_popup is set to 1")
                return
            }

            // Do we have a new order id?
            const newOrder: Order = this.unseenOrders[0]

            if (!newOrder) {
                this._log("checkNewOrder: no new order id")
                return
            }

            // We're already showing an order, skip for now
            if (this.showOrderId) {
                this._log("checkNewOrder: showOrderId is set")

                if (newOrder) {
                    this.playSound = true
                }

                return
            }

            if (
                this.isWaiterBuddy(newOrder) &&
                (newOrder?.delivery !== "deliver" || newOrder.table_id)
            ) {
                this._log("checkNewOrder: Skip waiterbuddy dinein order")
                return
            }

            if (!newOrder.is_complete) {
                await this.objectUpdated(newOrder.id)
            }

            // Should always be true because we filter out orders that are already seen
            if (!newOrder.has_seen) {
                this._log("checkNewOrder: not seen", false, newOrder)
                if (
                    newOrder.source.type === "kiosk" ||
                    (settings.print_auto !== "0" &&
                        (settings.print_when === "direct" ||
                            newOrder.source.type === "counter"))
                ) {
                    try {
                        this.playSoundOnce = true
                        const order = await axios.get(
                            "client/archive/" + newOrder?.id
                        )
                        this.saveOrder(order.data.data)
                        await printOrder(order.data.data).then(() =>
                            this._log(
                                "checkNewOrder: order has been sent to printer"
                            )
                        )
                        await this.acceptOrder(newOrder?.id).then(() =>
                            this._log("checkNewOrder: order has been accepted")
                        )
                    } catch (e) {
                        console.error(
                            "Could not auto accept order #" + newOrder?.id
                        )
                    }
                }

                await this.openOrderModal(
                    newOrder?.id,
                    true,
                    settings.show_popup === "time"
                )
            }
        },
        async acceptOrder(orderId: number): Promise<void> {
            this.updateOrder(orderId, { has_seen: true })
            await axios.post("/client/orders/seen/" + orderId)
        },
        closeOrderModal(): void {
            this.showOrderId = null
            this.autoOpened = false
            this.playSound = false

            // Dirty hack to ensure the modal gets closed, and reopened. Which should correctly adjust all data show in modal
            setTimeout(() => this.checkNewOrders(), 700)
        },
        toCartOrder(order: Order): any {
            order = cloneDeep(order)
            const cartStore = useCartStore()
            const customer = Object.assign(
                getDefaultCustomer(),
                order.customer_id
                    ? {
                          id: order.customer_id,
                          address: order.address,
                          company: order.company,
                          first_name: order.first_name,
                          last_name: order.last_name,
                          billing_address: order.billing_address,
                          phone: order.phone,
                          email: order.email,
                          invoice: { reference: { value: order.source.id } },
                          notes: order.customer?.notes || null,
                      }
                    : {}
            )

            if (
                (order.packaging_costs ?? 0) > 0 &&
                order.has_custom_packaging_costs
            ) {
                usePOSStore().packaging_costs = order.packaging_costs ?? 0
            }

            return {
                currentCart: {
                    customer_id: order.customer_id,
                    cart: {
                        order_id: order.id,
                        products: order.products.map(
                            (product: OrderProduct) => {
                                const discountId = (
                                    product.description || ""
                                ).match(/(dpp?\d+)$/)

                                return {
                                    id: product.product_id,
                                    order_product_id: product.id,
                                    title:
                                        product.description ||
                                        product.product?.title?.counter ||
                                        product.product?.title?.default ||
                                        null,
                                    price: product.price_unit,
                                    quantity: product.quantity,
                                    items: product.extras,
                                    remarks: product.remarks,
                                    discount_id: discountId
                                        ? discountId[0]
                                        : null,
                                    total: product.total,
                                    discount: product.discount,
                                    deposit: product.deposit,
                                    is_free:
                                        discountId &&
                                        discountId[0].startsWith("dpp"),
                                }
                            }
                        ),
                        tip: order.tip,
                        type: order.delivery || cartStore.defaultDispatchType,
                        points: order.points.paid || 0,
                        zip_code: order.address.zipcode || null,
                        schedule: {
                            time: order.is_delivery_asap
                                ? null
                                : order.time_set,
                            date: DateTime.fromISO(order.created_at)
                                .toUTC()
                                .toFormat("yyyy-MM-dd"),
                            asap: order.is_delivery_asap,
                        },
                        discounts: (order.discounts || []).map((discount) => {
                            let type = discount.type
                            if (
                                [
                                    DiscountType.SIMPLE.valueOf(),
                                    DiscountType.DISCOUNTED_PRODUCT.valueOf(),
                                    DiscountType.FREE_PRODUCT.valueOf(),
                                ].includes(discount.type)
                            ) {
                                type = "code"
                            }

                            if (
                                [
                                    DiscountType.CUSTOM.valueOf(),
                                    DiscountType.EXTERNAL.valueOf(),
                                ].includes(type)
                            ) {
                                return {
                                    type: type,
                                    // @ts-ignore
                                    code: "",
                                    custom: {
                                        type: discount.amount_type.valueOf(),
                                        description: discount.label,
                                        // @ts-ignore
                                        amount: discount.amount_type_value,
                                    } as CartDiscount,
                                }
                            }

                            return {
                                type: type,
                                is_external: false,
                                code: discount.code || "",
                            } as CartDiscount
                        }),
                        payment_method: order.payment.method,
                        payment2: {
                            method: order.payment2.method,
                            amount: order.payment2.amount,
                        },
                        payment_status: order.payment.status,
                        remarks: order.remarks,
                        products_can_be_updated: order.products_can_be_updated,
                    },
                    costs: {
                        payment: order.payment.costs,
                        delivery: order.delivery_costs,
                        packaging: 0,
                        deposit: order.deposit,
                        tip: order.tip,
                    },
                    choices: [],
                    discounts: order.discounts,
                    points: {
                        spent: order.points.paid,
                        earned: order.points.earned,
                    },
                    discount: order.discount.amount,
                    subtotal: order.subtotal,
                    total: order.total,
                    total_rounded: order.total_rounded,
                    cash_amount:
                        order.payment.method === PaymentMethod.CASH.valueOf()
                            ? parseFloat(order.payment.option)
                            : null,
                    table_id: order.table_id,
                    is_loaded_from_order: true,
                },
                customer: customer,
                table_id: order.table_id,
                existingOrderWasPaid: order.payment.status === "paid",
            }
        },
        async storeOrder(cartStore: CartState) {
            const masterSlave = useMasterSlave()

            return await axios.post(
                "/client/orders?fields[orders]=id,client_id,nr,refnr,source,address,has_seen,is_new_customer,table_name,is_v3,payment,created_at,time,time_set,total,status,delivery,distance,duration,payment,payment2,vat_rates,paid_at,phone_code,discount,total_netto,total_rounded,language,table_id,table,delivery_costs,products_can_be_updated,remarks&include=customer,products",
                Object.assign(cloneDeep(cartStore.currentCart), {
                    customer: {
                        id: cartStore.customer.id || null,
                        notes: cartStore.customer.notes,
                    },
                    address: cartStore.customer.address,
                    onlinePayment: {
                        linkVia: cartStore.onlinePaymentLinkVia,
                        email: cartStore.customer.email,
                        phone: cartStore.customer.phone,
                    },
                    is_slave: masterSlave.isSlave.value,
                    device_id: masterSlave.obId,
                    cart: {
                        ...cloneDeep(cartStore.currentCart.cart),
                        payment_method:
                            cartStore.currentCart.cart.payment_method ===
                            PaymentMethod.PIN_CASH
                                ? PaymentMethod.CASH
                                : cartStore.currentCart.cart.payment_method,
                    },
                    invoice_reference:
                        cartStore.customer.invoice?.reference?.value,
                }),
                {
                    params: {
                        main_location_id: multiLocationStore().isActive
                            ? multiLocationStore().main_location_id
                            : 0,
                        multi_location_id: multiLocationStore().isActive
                            ? multiLocationStore().selected_location_id_for_menu
                            : 0,
                    },
                    paramsSerializer: paramsSerializer,
                }
            )
        },
    },
})
