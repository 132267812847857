<template>
    <div class="wrap" :class="page">
        <PageHeader v-if="showMenu"></PageHeader>
        <div
            class="page grid grid-cols-10"
            :style="
                page === 'auth' ? 'background-image: url(' + bg_path + ')' : ''
            "
        >
            <router-view></router-view>
        </div>
    </div>
    <template v-if="showModals">
        <PinCodeModal v-if="apiStore.requirePincode" />
        <SystemSetupMasterSlaveModal v-if="showMasterSlaveSetup" />
        <AdminClientInfoModal
            v-if="userStore.user.id > 0 && userStore.admin_info_modal_open"
        />
        <FileBrowser v-if="fileBrowserStore.openModal"></FileBrowser>
        <EmergencyBrakeModal v-if="brakeStore.show"></EmergencyBrakeModal>
        <CustomerModal v-if="customerStore.showModal"></CustomerModal>
        <CustomerReceiptConfirmation v-if="customerReceiptStore.showModal" />

        <template v-if="ordersStore.showOrderId">
            <OrderModal
                @close="ordersStore.closeOrderModal()"
                :orderId="ordersStore.showOrderId"
            />
        </template>

        <ConfirmationModal
            :show="warningStore.showWarning"
            @cancel="warningStore.showWarning = false"
            @confirm="warningStore.showWarning = false"
            :title="warningStore.warningTitle"
            :description="warningStore.warningDescription"
        />
        <RiceCookerUpdateModal />
    </template>
</template>

<script lang="ts" setup>
import PageHeader from "@/ui-elements/page-header/PageHeader.vue"
import { useRouter } from "vue-router"
import { computed, onMounted, ref, watch } from "vue"
import PinCodeModal from "@/app/PinCodeModal.vue"
import FileBrowser from "@/ui-elements/file-browser/FileBrowser.vue"
import EmergencyBrakeModal from "@/pages/orders/modals/brake/EmergencyBrakeModal.vue"
import { RiceCooker } from "@/riceCooker/riceCooker"
import CustomerModal from "@/pages/pos/modal/customer/CustomerModal.vue"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { useI18n } from "vue-i18n"
import { setFontSizeOnHTML, useFontSize } from "@/store/FontSize"
import { useAPIStore } from "@/store/API"
import { useUserStore } from "@/store/User"
import { useFileBrowserStore } from "@/store/fileBrowserStore"
import { useOrdersStore } from "@/store/Orders"
import OrderModal from "@/pages/orders/modals/order/OrderModal.vue"
import { useBrakeStore } from "@/store/Brake"
import { useCustomerModalStore } from "@/store/CustomerModal"
import "shepherd.js/dist/css/shepherd.css"
import CustomerReceiptConfirmation from "@/pages/pos/components/CustomerReceiptConfirmation.vue"
import { useCustomerReceiptStore } from "@/store/CustomerReceiptConfirmation"
import ConfirmationModal from "@/ui-elements/layout/modals/ConfirmationModal.vue"
import { useOrderWarningStore } from "@/store/OrderWarning"
import AdminClientInfoModal from "@/app/admin-client-info-modal/AdminClientInfoModal.vue"
import { useSettingsStore } from "@/store/Settings"
import axios from "axios"
import { getOrCreateObId } from "@/utils/useMasterSlave"
import RiceCookerUpdateModal from "@/components/RiceCookerUpdateModal.vue"
import SystemSetupMasterSlaveModal from "@/app/SystemSetupMasterSlaveModal.vue"
import {
    updateLocaleSettings,
    useLocalLocales,
} from "@/pages/auth/useLocalLocales"
import {
    devGetFrontendVersion,
    devGetRiceCookerVersion,
} from "@/utils/devUtils"
import { offlineModeStore } from "@/store/offlineMode"
const { t: translate } = useI18n()
const fontSize = useFontSize()
setFontSizeOnHTML(fontSize.getSize())
const router = useRouter()
const settings = useSettingsStore()
const userStore = useUserStore()
const ordersStore = useOrdersStore()

if (RiceCooker.isPresent() && !localStorage.getItem("localStorageSynced")) {
    RiceCooker.syncLocalStorage()
}

const settingsStore = useSettingsStore()
const { getDefaultLocale } = useLocalLocales()

const showMasterSlaveSetup = ref(false)

watch(
    () => [
        settingsStore.settings.masterslave,
        settingsStore.settings.master_ob_id,
        userStore.hasSetupMasterSlave,
    ],
    () => {
        showMasterSlaveSetup.value =
            Boolean(Number(settingsStore.settings.masterslave)) &&
            settingsStore.settings.master_ob_id !==
                localStorage.getItem("ob_id") &&
            !userStore.hasSetupMasterSlave
    }
)

onMounted(async () => {
    updateLocaleSettings(getDefaultLocale())
})

const apiStore = useAPIStore()
const fileBrowserStore = useFileBrowserStore()
const customerStore = useCustomerModalStore()
const customerReceiptStore = useCustomerReceiptStore()
const brakeStore = useBrakeStore()
const warningStore = useOrderWarningStore()

const page = computed(() => {
    const currentRoute = router.currentRoute.value
    if (currentRoute && currentRoute.matched.length) {
        return currentRoute.meta.name
    } else {
        return undefined
    }
})

const pagesWithoutMenu = [
    "auth",
    "numberBuddy",
    "impersonation",
    "clientSso",
    "customerScreen",
]

const showMenu = computed(
    () =>
        !pagesWithoutMenu.includes(page.value as string) &&
        apiStore.hasBearerToken()
)
const showModals = computed(() => page.value !== "auth")
const bg_path = "/assets/images/auth-bg.png"

watch(
    () => settings.pos2Screen,
    () => {
        if (settings.pos2Screen) {
            RiceCooker.openCustomerScreen()
        }
    }
)

window.onerror = (message) => {
    const genericMessage = translate("please_try_again")
    const content = message ? `${genericMessage} (${message})` : genericMessage
    setToastNotification(translate("something_went_wrong"), content, "danger")
    return true
}

window.onunhandledrejection = (errorEvent) => {
    const genericMessage = translate("please_try_again")
    const content = errorEvent.reason
        ? `${genericMessage} (${errorEvent.reason})`
        : genericMessage
    setToastNotification(translate("something_went_wrong"), content, "danger")
    return true
}
</script>

<style lang="scss">
.wrap {
    display: flex;
    flex-flow: column nowrap;
    background: $light-gray;
    position: relative;
    min-height: 100vh;
    height: 100vh;
    width: 100%;
}

.page {
    width: 100%;
    height: 100%;
    position: relative;
    padding: $padding-s;
    overflow: auto;
    flex: 1 1;
    background-position: center;
    background-size: cover;
}

@media only screen and (max-width: $screen-mobile) {
    .side-menu {
        column-span: none;
        display: none;
    }

    .page-content {
        grid-column: span 10 / span 10 !important;
        flex-wrap: wrap !important;
        overflow: initial !important;
    }
}
</style>
